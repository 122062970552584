import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import TextDialog from "../components/core/TextDialog";
import { cancelShowingWithShowami } from "../services/userShowings";
import { useNavigate, useParams } from "react-router-dom";
const useStyles = makeStyles((theme) => {
	return {
		root: {
			position: "relative",
			width: "100%",
			overflow: "hidden",
			paddingTop: "100%"
		},
		responsiveIframe: {
			position: "absolute",
			position: "fixed",
			top: 80,
			left: 0,
			bottom: 0,
			right: 0,
			width: "100%",
			height: "85%",
			border: "none",
			overflow: "hidden"
		},
		loader: {
			position: "fixed",
			left: "50%",
			top: "50%"
		}
	};
});

function CancelShowing({ route }) {
	const classes = useStyles();
	const navigate = useNavigate();

	let { userId, showingId } = useParams();

	const [showConfirmOfferSent, setShowConfirmOfferSent] = useState({
		message: "",
		state: false
	});
	useEffect(() => {
		const cancelShowing = async () => {
			try {
				const resp = await cancelShowingWithShowami({
					userId,
					showingId
				});
				if (resp.data.success) {
					setShowConfirmOfferSent((prevState) => {
						return {
							message: "Showing has been cancelled successfully.",
							state: true
						};
					});
				}
			} catch (e) {}
		};

		cancelShowing();
	}, []);

	return (
		<Container maxWidth="lg" className={classes.root}>
			{showConfirmOfferSent.state && (
				<TextDialog
					showActions={true}
					message={showConfirmOfferSent.message}
					state={showConfirmOfferSent.state}
					stateHandler={setShowConfirmOfferSent}
					submitHandler={() => {
						navigate(`/app/dashboard`, {
							replace: true
						});
					}}
					onClose={() => {
						navigate(`/app/dashboard`, {
							replace: true
						});
					}}
					showField={false}
				></TextDialog>
			)}
		</Container>
	);
}

export default CancelShowing;
